.app__locations {
    text-align: center;
}

.app__locations h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 30px;
    line-height: 50px;
    font-weight: 400;
    letter-spacing: 0.04em;
}

.app__locations-maps {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: start;
}


.mapouter{
    height:250px;
    width: 400px;
}

#branch-loc {
    margin-top: 2rem;
}

.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:100%;
    width:100%;
}

#gmap_canvas {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .app__locations-maps {
        justify-content: space-between;
    }

    .mapouter{
        height:250px;
        width: 90%;
    }
}

@media screen and (max-width: 650px) {
    .app__locations-maps {
      flex-direction: column;
      align-items: center;
    }

    .app__locations-maps_Ezulwini {
        margin-top: 2rem;
    }

    .mapouter{
        height:250px;
        width: 100%;
    }
}