.app__aboutus {
    position: relative;
    height: 100%;
}

.app__aboutus-overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    inset: 0;
    opacity: 0.2;
}

.app__aboutus-overlay img {
    width: 500px;
    height: 500px;
    z-index: 0;    
}

.app__aboutus-content {
    position: relative;
    width: 100%;
    z-index: 2;
    gap: 10%;
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: right;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_history p,
.app__aboutus-content_about p {
    margin: 2rem 0;
    color: #ccc;
}

@media screen and (max-width: 2000px) {
    .app__aboutus-content_history p,
    .app__aboutus-content_about p {
        margin: 4rem 0;
}
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 400px;
        height: 400px;
    }
}