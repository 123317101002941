#app__contactUs-contact {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
}

#app__contactUs-contact div h3 {
    font-family: var(--font-alt);
    color: var(--color-white);
    margin: 1rem auto;
}

#app__contactUs-contact .p__contact {
    font-family: 'Open Sans', sans-serif;
    color: var(--color-white);
    margin: 0.5rem 0;
}

#app__contactUs-contact a {
    font-family: 'Open Sans', sans-serif;
    color: var(--color-golden);
}

@media screen and (max-width:850px) {
    #app__contactUs-contact {
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: -3rem;
    }
}