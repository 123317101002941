.app__contactUs h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 40px;
    line-height: 80px;
    font-weight: 400;
    letter-spacing: 0.04em;
}

.app__contactUs-contact {
    padding-top: 2rem;
    gap: 6rem;
}

.mapouter{
    height:500px;
    width: 100%;
    margin:  1rem auto;
}

.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:100%;
    width:auto;
}

#gmap_canvas {
    width: 100%;
    height: 100%;
}

.app__contactUs-social {
    text-align: center;
}

@media screen and (max-width:850px) {
    .app__contactUs h1 {
        font-size: 30px;
        line-height: 60px;
    }
    
    .app__contactUs-contact {
        flex-direction: column;
        padding-top: 1.5rem;
    }
}

@media screen and (max-width:550px) {
    .app__contactUs h1 {
        font-size: 25px;
        line-height: 40px;
    }
    
    .app__contactUs-contact {
        padding-top: 1rem;
    }
}