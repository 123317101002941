.app__video {
    height: 100vh;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0 auto;
    background: rgba(0, 0, 0, 0.3);
}

.app__video-overlay_circle {
    width: 85px;
    height: 85px;
    border-radius: 5px;
    border: 2px dotted var(--color-golden);
    background: rgba(218, 165, 32, 0.533);
    cursor: pointer;
}