.app__menuitem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__menuitem-content {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
}

.app__menuitem-img {
    width: 100px;
    margin-right: 1rem;
    margin-top: 1rem;
}

.app__menuitem-img img {
    width: 100%;
    border-radius: 5px;
    transition: transform 0.2s;
}

.app__menuitem-img img.zoomed {
    transform: scale(4);
    margin-left: 150%;
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;    
}

.app__menuitem-name {
    flex: 1;
    text-align: start;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0px 1rem;
}

.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}