.sliding-text-container h2  {
  font-family: var(--font-base);
  color: var(--color-golden);
  letter-spacing: 0.04em;
  line-height: 29.9px;
  font-size: 20px;
}

.sliding-text-container {
  overflow: hidden;
  padding: 0.5rem 2rem;
}

.sliding-text {
  white-space: nowrap;
  animation: slide 23s linear infinite;
  margin-top: 0.3rem;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.vacancies-btn_mobile {
  display: none;
}

@media screen and (max-width:650px) {
  .vacancies-btn_mobile {
    display: flex;
    color:rgb(111, 180, 183);
    font-style: var(--font-base);
  }

  .sliding-text {
    animation: slide 15s linear infinite;
  }
}