.app__gallery-page {
    display: flex;
    flex-direction: column;
    background-color: #000;
    width: 100%;
    padding: 0 2rem 1rem 2rem;
    text-align: center;
}

.app__gallery-page_container {
    display: flex;
    justify-content: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
}

.app__gallery-page_container div {
    margin: 10px;
    cursor: pointer;
}

.app__gallery-page_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-page_card {
    position: relative;
    min-width: 300px;
    height: 445px;
    margin-right: 2rem;
}

.app__gallery-page_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: 0.5s ease;
}

.app__gallery-page_card:hover img {
    opacity: 0.75;
}

.app__gallery-page_card:hover {
    opacity: 1;
}

/* overlay */

.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .overlay-content {
    max-width: 80%;
    max-height: 80%;
    overflow-x: hidden;
  }
  
  .overlay-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: 1s ease;
  }
  
  .overlay-content img:hover {
    transform: scale(1.1);
  }


@media screen and (min-width: 2000px) {

    .app__gallery-page_card {
        min-width: 400px;
        height: 550px;
    }
}

@media screen and (max-width: 1150px) {

    .app__gallery-page {
        margin: 5rem 0;
    }
}

@media screen and (max-width: 650px) {

    .app__gallery-page_card {
        min-width: 240px;
        height: 320px;
    }
}