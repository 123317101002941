.app__tablesMap h1 {
    color: aliceblue;
}

.app__tablesMap option {
    font-family: var(--font-alt);
    color: var(--color-black);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
    cursor: pointer;
}

