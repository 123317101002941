.app__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/mainbg.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease-in;
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    background: black;
    padding: 0.5rem;
    border-radius: 2rem 20rem 0 0;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size: 60px;
    animation-name: slideInFromLeft;
    animation-duration: 5s;
} 


@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

#main-p {
    background: #000;
    padding: 0.5rem;
    border-radius: 0 0 10rem 1rem;
    margin-bottom: 2rem;
} 

.app__header button {
    animation-name: slideInFromRight;
    animation-duration: 8s;
}

@keyframes slideInFromRight {
    from {
        transform: translateX(130%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.app__header .app__wrapper_img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.app__wrapper_img-header {
    background-color: #000;
    color: #9fff64;
    padding: 0.5rem 1rem;
    font-family: var(--font-base);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}


@media screen and (max-width: 1150px) {
    .app__header-h1 {
        letter-spacing: 0.03em;
        font-size: 50px;
    } 
}

@media screen and (max-width: 850px) {
    .app__header-h1 {
        font-size: 45px;
        background-color: transparent;
    } 

    #main-p {
        background: transparent;
    } 
}

@media screen and (max-width: 850px) {
    .app__header {
        flex-direction: column;
    }
}
