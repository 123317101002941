.app__landingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
}

.app__landingPage-subText {
    font-family: var(--font-base);
    color: var(--color-white);
    font-size: 40px;
    line-height: 55px;
    font-weight: 300;
    text-transform: capitalize;
}

.app__landingPage_dropDown-container {
    position: relative;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 300px;
    padding: 1rem 2rem;
    box-shadow: 0px 5px 16px 0px rgba(0,0,0,0.2);
    background-color: #0000003d;
    z-index: 1;
    flex-direction: column;
    margin-top: 13rem;
    transition: all 1;
}

.show {
    display: flex; 
}

.dropdown-content-ezulwiniBtn {
    margin-top: 2rem;
}

#branches:hover {
    color: var(--color-golden);
}