.POP {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 500;
    letter-spacing: 0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 23px;
}

.POP__email {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 29.9px;
    font-size: 23px;
    text-decoration: underline;
    color: orange;
}

.app__payInfo-conditions_header {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
    margin: 1rem 0;
}

.app__payInfo-conditions {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    font-size: 11px;
    margin-bottom: 1rem;
}