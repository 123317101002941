.app__newsletter {
    width: 100%;
    height: 100%;
    background: var(--color-black);
    margin: 0;
}

#subscription-form {
    width: 590px;
}

#email {
    width: 100%;
}

@media screen and (max-width:620px) {
    #subscription-form {
        width: 100%;
    }
    
    #email {
        width: 100%;
    }
}