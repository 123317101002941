.app__gallery-page_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.app__gallery-page_sub {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 500;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 29px;
    padding: 1rem 0;
}