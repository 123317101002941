.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    padding: 0 1rem;
    transition: all 2s ease-in;
}

.slide {
    border-radius: 5px;
    box-shadow: 0 0 7px #777;
    width: 100%;
}

.slide-hidden {
    display: none;
}

.arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: #fff;
    filter: drop-shadow(0 0 5px #444)
}

.arrow:hover {
    cursor: pointer;
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.indicator {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0 0 5px #555;
    margin: 0 0.5rem;
    cursor: pointer;
}

.indicator-inactive {
    background-color: #999;
}

@media screen and (max-width:650px) {
    .carousel {
        width: 100%;
    }

    .carousel img {
        width: 300px;
    }

    .arrow-left {
        left: 1.5rem;
    }
    
    .arrow-right {
        right: 1.5rem;
    }

}