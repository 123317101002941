.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 5rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.app__chef-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 2rem;
}

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 50%;
    margin-top: 2rem;
}

@media screen and (max-width:850px) {
    #chef-img {
        margin-top: -6rem;
    }

    .app__chef-content {
        margin-top: 3rem;
    }

    .app__chef-content_quote img {
        width: 40px;
        height: 37px;
    }
    
    .app__chef-sign {
        width: 200px;
    }

    .app__chef-sign img {
        width: 100%;
        margin-top: 1rem;
    }
}

@media screen and (max-width:550px) {
    .app__chef-content {
        margin-top: 1.5rem;
    }

    .app__chef-content_quote img {
        width: 37px;
        height: 30px;
    }

    #chef-img {
        margin-top: 4rem;
    }

    .app__wrapper_info {
        margin-top: -11px;
    }

    .app__wrapper_info h1 {
        font-size: 30px;
    }
    
    .app__chef-sign {
        width: 153px;
    }

    .app__chef-sign p:first-child {
        font-size: 23px;
        margin-top: -20px;
    }

    .app__chef-sign img {
        margin-top: 1rem;
    }
}